<template>
  <el-dialog title="交易身份证明材料" :visible.sync="visible" custom-class="min-width" :close-on-click-modal="false" draggable>
    <el-form ref="dataFormRef" :model="form" :rules="dataRules" formDialogRef label-width="250px" v-loading="loading">
      <el-row :gutter="24">
        <el-col :span="24" class="mb20">
          <el-divider>文件上传</el-divider>
        </el-col>
        <el-col :span="10" class="mb20">
          <el-form-item label="身份证明材料" prop="identityFiles">
            <div style="display: flex; flex-direction: row; column-gap: 12px">
              <div v-if="form.identityFiles.length ===0">
                <upload @childUpload="parentGetData" @on-preview="previewFile" name="identityFiles" />
                <div class="form-item-desc">营业执照/机构代码/法人证明/法人身份证（复印件加盖单位印章）</div>
              </div>
              <ul v-else class="navWrap_center_ul">
                <li
                    v-for="(item, index) in form.identityFiles"
                    :key="index"
                    @click="downloadFile(item.url)"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="10" class="mb20">
          <el-form-item label="委托文件" prop="proxyFiles">
            <div  v-if="form.proxyFiles.length===0">
              <div style="display: flex; flex-direction: row; column-gap: 12px">
                <upload @childUpload="parentGetData" @on-preview="previewFile" name="proxyFiles" />
              </div>
              <div class="form-item-desc">经办人委托书及其身份证复印件（代办必须）</div>
            </div>
            <ul v-else class="navWrap_center_ul">
              <li
                  v-for="(itemProxy, index) in form.proxyFiles"
                  :key="index"
                  @click="downloadFile(itemProxy.url)"
              >
                {{ itemProxy.name }}
              </li>
            </ul>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="10" class="mb20">
          <el-form-item label="企业章程文件" prop="companyFiles">
            <div style="display: flex; flex-direction: row; column-gap: 12px"  v-if="form.companyFiles.length===0">
              <upload @childUpload="parentGetData"  @on-preview="previewFile" name="companyFiles" />
            </div>
            <ul v-else class="navWrap_center_ul">
              <li
                  v-for="(itemcompany, index) in form.companyFiles"
                  :key="index"
                  @click="downloadFile(itemcompany.url)" >
                {{ itemcompany.name }}
              </li>
            </ul>
          </el-form-item>
        </el-col>

        <el-col :span="10" class="mb20">
          <el-form-item label="股东会决议文件" prop="shareholderFiles">
            <div style="display: flex; flex-direction: row; column-gap: 12px"  v-if="form.shareholderFiles.length===0">
              <upload @childUpload="parentGetData"  @on-preview="previewFile" name="shareholderFiles" />
            </div>
            <ul v-else class="navWrap_center_ul">
              <li
                  v-for="(itemShareholder, index) in form.shareholderFiles"
                  :key="index"
                  @click="downloadFile(itemShareholder.url)" >
                {{ itemShareholder.name }}
              </li>
            </ul>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer >
			<span class="dialog-footer" v-if="!form.fileId">
				<el-button @click="visible = false">取消</el-button>
				<el-button type="primary" @click="onSubmit" :disabled="loading">确认</el-button>
			</span>
    </template>

  </el-dialog>
</template>

<script setup  name="demandTradeFilesDialog">
import { useDict } from '@/hooks/dict';
import {Message} from 'element-ui'
import {reactive, ref, defineAsyncComponent, nextTick} from 'vue'
import  CommonService  from '@/service/CommonService';

const { transaction_person_type } = useDict('transaction_person_type');

const Upload = defineAsyncComponent(() => import('@/components/Upload/NewFile.vue'));

const parentGetData = (value,name) => {
  form[name] = value;

};



// 定义变量内容
const dataFormRef = ref();
const visible = ref(false);
const loading = ref(false);


// 提交表单数据
const form = reactive({
  id: '',
  landId: '',
  personType: '',
  userId: localStorage.getItem('user_id'),
  identityFiles: [], //营业执照/机构代码/法人证明/法人身份证(复印件加盖单位印章) List
  companyFiles: [], //企业章程文件 List
  shareholderFiles: [] , //	股东会决议 List
  proxyFiles: [] , //经办人委托书及其身份证复印件 List, 代办才有此字段
});

const previewFile = (file) => {
  window.open(file.url);
};


const downloadFile = (file) => {
  window.open(file);
};
// 定义校验规则
const dataRules = ref({
  parentGetData: [{ required: true, message: '营业执照/机构代码/法人证明/法人身份证 不能为空', trigger: 'blur' }],
});

// 打开弹窗
const openDialog = (param , type) => {
  form.personType = '';
  if (type === 'protocol' ||  type === 'rent') {
    form.personType = param.sellerId === localStorage.getItem('user_id') ? 'seller' : 'buyer';
    form.fileId = form.personType === 'seller' ? param.sellerIdentityId  : param.buyerIdentityId ;

  }
  visible.value = true;
  form.transactionId = param.id;
  form.transactionType = type;
  form.identityFiles = [];
  form.companyFiles = [];
  form.shareholderFiles = [];
  form.proxyFiles = [];


  // 重置表单数据
  nextTick(() => {
    form.fileId > 0 ? '' : form.fileId = null;
    dataFormRef.value?.resetFields();
  });
  if  ( (type === 'listing'  || type === 'auction' )&& param.buyerIdentityId > 0) {
    form.fileId = param.buyerIdentityId;
  }

  if (form.fileId > 0 ) {
    getAgreementTradeFileData(form.fileId);
  }
};

// 提交
const onSubmit =  () => {
  const valid =  dataFormRef.value.validate().catch(() => {});
  if (!valid) return false;

  try {
    loading.value = true;
    if (form.id) {
      CommonService.putAgreementTradeFiles(form).then(res => {
        if (res && res.code === 0) {
          Message.success('修改资料成功');
        }else {
          Message.error(res.msg);
        }
      });
    } else {
      CommonService.addAgreementTradeFiles(form).then(res => {
        if (res && res.code === 0) {
          Message.success('添加资料成功');
        }else {
          Message.error(res.msg);
        }
      });
    }
    visible.value = false;
  } catch (err) {

    Message.error(err.msg);
  } finally {
    loading.value = false;
  }
};

const getAgreementTradeFileData = (id) => {
  // 获取数据
  loading.value = true;
  CommonService.getAgreementTradeFiles(id).then((res) => {
    if (res.data) {
      Object.assign(form, res.data);
    }
  }).finally(() => {
    loading.value = false;
  });
};



// 暴露变量
defineExpose({
  openDialog,
});
</script>
<style>
.form-item-desc {
  font-size: 12px;
  color: red;
}
.min-width{
  width: 1300px;
}
</style>
